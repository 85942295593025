import React from "react"
import { Link } from "gatsby"

import navStyles from "./nav.module.scss"

const Nav = () => {
  return (
    <header className={navStyles.header}>
      <nav className={navStyles.navList}>
        <Link
          to="/"
          className={navStyles.navItem}
          activeClassName={navStyles.activeNavItem}
        >
          Home
        </Link>

        <Link
          to="/about/"
          className={navStyles.navItem}
          activeClassName={navStyles.activeNavItem}
        >
          About
        </Link>

        <Link
          to="/projects/"
          className={navStyles.navItem}
          activeClassName={navStyles.activeNavItem}
        >
          Projects
        </Link>

        <Link
          to="/contact/"
          className={navStyles.navItem}
          activeClassName={navStyles.activeNavItem}
        >
          Contact
        </Link>
      </nav>
    </header>
  )
}

export default Nav
