import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import footerStyles from "./footer.module.scss"

const Footer = () => {
  const Year = new Date()
  return (
    <div className={footerStyles.footer}>
      <p style={{ color: "#1c528a", marginRight: "50px" }}>
        © {Year.getFullYear()}
      </p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="62.794"
        height="23.18"
        viewBox="0 0 62.794 23.18"
      >
        <g id="Social" transform="translate(-1780 -59.553)">
          <OutboundLink href="https://twitter.com/urlDev" aria-label="Twitter">
            <g id="Twitter" transform="translate(1780 60)">
              <path
                id="Path_2"
                className={footerStyles.twitter}
                data-name="Path 2"
                d="M46.875,24.733c10.588,0,16.349-8.719,16.349-16.349V7.605a12.657,12.657,0,0,0,2.8-2.958,12.926,12.926,0,0,1-3.27.934,6.058,6.058,0,0,0,2.491-3.114,14.282,14.282,0,0,1-3.581,1.4A5.561,5.561,0,0,0,57.463,2,5.85,5.85,0,0,0,51.7,7.761a3.036,3.036,0,0,0,.156,1.246A16.1,16.1,0,0,1,40.024,2.934a5.963,5.963,0,0,0-.779,2.958,6.186,6.186,0,0,0,2.491,4.827,5.249,5.249,0,0,1-2.647-.779h0a5.69,5.69,0,0,0,4.671,5.605A4.8,4.8,0,0,1,42.2,15.7a2.65,2.65,0,0,1-1.09-.156,5.9,5.9,0,0,0,5.45,4.048A11.756,11.756,0,0,1,39.4,22.086,4.311,4.311,0,0,1,38,21.93a14.7,14.7,0,0,0,8.875,2.8"
                transform="translate(-38 -2)"
                fill="#1c528a"
                fillRule="evenodd"
              />
            </g>
          </OutboundLink>
          <OutboundLink href="https://github.com/urlDev" aria-label="Github">
            <g
              id="Layer_2"
              data-name="Layer 2"
              transform="translate(1819.338 59.553)"
            >
              <g id="Layer_1" data-name="Layer 1" transform="translate(-0.003)">
                <path
                  id="Path_327"
                  className={footerStyles.twitter}
                  data-name="Path 327"
                  d="M11.724,0A11.727,11.727,0,0,0,8.019,22.853c.586.107.8-.255.8-.566,0-.278-.01-1.016-.016-1.994-3.262.708-3.95-1.572-3.95-1.572a3.106,3.106,0,0,0-1.3-1.715c-1.065-.727.081-.713.081-.713a2.462,2.462,0,0,1,1.8,1.209,2.5,2.5,0,0,0,3.413.975,2.507,2.507,0,0,1,.744-1.568c-2.607-.3-5.342-1.3-5.342-5.8A4.535,4.535,0,0,1,5.454,7.967a4.221,4.221,0,0,1,.115-3.1s.985-.315,3.224,1.2a11.1,11.1,0,0,1,5.872,0c2.238-1.517,3.221-1.2,3.221-1.2A4.216,4.216,0,0,1,18,7.967a4.525,4.525,0,0,1,1.205,3.147c0,4.5-2.741,5.5-5.354,5.786a2.8,2.8,0,0,1,.8,2.172c0,1.567-.014,2.832-.014,3.216,0,.314.211.679.806.564A11.728,11.728,0,0,0,11.724,0Z"
                  transform="translate(0.003 0)"
                  fill="#1c528a"
                  fillRule="evenodd"
                />
              </g>
            </g>
          </OutboundLink>
        </g>
      </svg>
    </div>
  )
}

export default Footer
