import React from "react"

import Nav from "./Nav"
import Footer from "./Footer"

import layoutStyles from "./layout.module.scss"

const Layout = props => {
  return (
    <>
      <Nav />
      <div className={layoutStyles.container}>{props.children}</div>
      <div
        style={{
          background: "#FBFAFE",
          height: "324px",
          zIndex: "-1",
          // position: "relative",
          // top: "-150px",
          marginTop: "-210px",
        }}
      ></div>
      <div className={layoutStyles.container}>
        <Footer />
      </div>
    </>
  )
}

export default Layout
