import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Head = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          author
          url
          description
          image
        }
      }
    }
  `)

  const { siteTitle, author, url, image, description } = data.site.siteMetadata

  return (
    <Helmet title={`${title} | ${author}`}>
      <meta charSet="utf-8" />
      <html lang="en-US" />
      <meta name="theme-color" content="#ffd4c4" />
      <meta name="title" content={`${siteTitle}`} />
      <meta name="description" content={`${description}`} />
      <meta name="author" content={`${author}`} />
      <link rel="apple-touch-icon" href="../../static/logoSecond.png" />
      <meta property="og:title" content={`${siteTitle} - ${author}`} />
      <meta property="og:type" content="Website" />
      <meta
        name="description"
        property="og:description"
        content={`${description}`}
      />

      <meta
        name="image"
        property="og:image"
        content="https://i.paste.pics/c024e3f67e9249ee9253778e782a9808.png"
      />
      <meta property="og:url" content={`${url}/twitterImage.jpg`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={`${url}/twitterImage.jpg`} />
      <meta property="twitter:title" content={`${siteTitle} - ${author}`} />
      <meta property="twitter:description" content={`${description}`} />
      <meta property="twitter:image" content={`${url}/twitterImage.jpg`} />
    </Helmet>
  )
}

export default Head
